<!--国家奖学金申请-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">单项积极分子申请</div>
    <table
      id="Panel1"
      class="DivTable"
      cellpadding="3"
      cellspacing="0"
      v-if="showtop"
    >
      <tbody>
        <tr>
          <td width="15%" class="EdtTitleBack"><strong>当前学年</strong>：</td>
          <td class="EdtBack">
            <span id="TermYear" style="color: #ff0033; font-weight: bold">{{
              pcmodel.xn
            }}</span>
          </td>
        </tr>
        <tr>
          <td width="15%" class="EdtTitleBack">
            <strong>申请时间段</strong>：
          </td>
          <td class="EdtBack">
            <span id="BeginDate">{{ dateFormat2(pcmodel.kssj) }}</span
            >&nbsp;至&nbsp;<span id="EndDate">{{
              dateFormat2(pcmodel.jssj)
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack"><strong>重点提示</strong>：</td>
          <td class="EdtBack">
            <span id="MostSay" style="color: #ff0033; font-weight: bold">{{
              pcmodel.ts
            }}</span>
          </td>
        </tr>
        <tr>
          <td class="EdtTitleBack">
            <strong><span style="font-family: 宋体">说明</span></strong
            >：
          </td>
          <td class="EdtBack">
            <span id="Memo">{{ pcmodel.sm }}</span>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="EdtBack" style="text-align: center">
            <el-button type="primary" @click="showAdd">提交申请</el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 300"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="200"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="200"></el-table-column>
        <el-table-column prop="xb" label="性别" width="200">
          <template slot-scope="scope">
            {{ scope.row.xbm == 1 ? '男' : '女' }}
          </template>
        </el-table-column>
        <el-table-column prop="xn" label="学年" width="300"> </el-table-column>
        <el-table-column prop="rych" label="荣誉称号" width="300">
        </el-table-column>
        <el-table-column prop="zt" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.spzt === 0">待辅导员审核</el-tag>
            <el-tag v-if="scope.row.spzt === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 110" type="success"
              >院系审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 120" type="danger"
              >院系审核拒绝</el-tag
            >
            <el-tag v-if="scope.row.spzt === 111" type="success"
              >学工审核通过</el-tag
            >
            <el-tag v-if="scope.row.spzt === 112" type="danger"
              >学工审核拒绝</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              @click="handleDetail(scope.row)"
              >查看</el-button
            >
            <el-button
              v-if="scope.row.spzt == 111"
              size="mini"
              type="success"
              @click="print(scope.row.id)"
              >打印审批表</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--添加弹出框-->
    <el-dialog
      :title="operation == 1 ? '新增' : '编辑'"
      :visible.sync="dialogFormVisible"
      width="60%"
      :close-on-click-modal="false"
      @close="closeDialog"
      top="5vh"
    >
      <el-scrollbar style="height: 500px" class="myscrollbar">
        <div class="form-item-group" v-if="operation != 1">
          <i class="fa fa-address-book-o"></i>
          <div>
            审批流程 当前状态：
            <el-tag v-if="forminfo.SPZT === 0">待辅导员审核</el-tag>
            <el-tag v-if="forminfo.SPZT === 100" type="success"
              >辅导员审核通过</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 200" type="danger"
              >辅导员审核拒绝</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 110" type="success"
              >系主任审核通过</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 120" type="danger"
              >系主任审核拒绝</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 111" type="success"
              >系分管领导审核通过</el-tag
            >
            <el-tag v-if="forminfo.SPZT === 112" type="danger"
              >系分管领导审核拒绝</el-tag
            >
          </div>
        </div>
        <el-timeline>
          <el-timeline-item
            v-for="(item, index) in splist"
            :key="index"
            :timestamp="dateFormat2(item.cjsj)"
            placement="top"
          >
            <el-card>
              <h4 v-if="item.spzt == 100 || item.spzt == 200">
                辅导员审批{{ item.spzt == 100 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 110 || item.spzt == 120">
                院系审批{{ item.spzt == 110 ? '通过' : '拒绝' }}
              </h4>
              <h4 v-if="item.spzt == 111 || item.spzt == 112">
                学工审批{{ item.spzt == 111 ? '通过' : '拒绝' }}
              </h4>
              <p>审批意见：{{ item.spyj }}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
        <el-form
          :model="forminfo"
          v-if="dialogFormVisible"
          ref="formRef"
          :rules="forminfoRules"
        >
          <el-card class="box-card">
            <span class="staffBmTitle"
              >{{ pcmodel.xn }}“单项积极分子”申请审批表</span
            >
            <table border="1px" class="staffBmTable">
              <tr>
                <td style="width: 100px">姓名</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.XM">
                    </el-input>
                  </el-form-item>
                </td>
                <td style="width: 100px">性别</td>
                <td style="width: 100px">
                  <el-form-item style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.XB">
                    </el-input>
                  </el-form-item>
                </td>
                <td style="width: 100px">出生年月</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.CSNY">
                    </el-input>
                  </el-form-item>
                </td>
                <td style="width: 100px">民族</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.MZ">
                    </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>所在班级</td>
                <td colspan="3">
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.BJMC">
                    </el-input>
                  </el-form-item>
                </td>
                <td>学号</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.XH">
                    </el-input>
                  </el-form-item>
                </td>
                <td>所在宿舍</td>
                <td>
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.FJBH">
                    </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td>政治面貌</td>
                <td colspan="3">
                  <el-form-item label-width="0px" style="margin-bottom: 0px">
                    <el-input size="small" readonly v-model="forminfo.ZZMM">
                    </el-input>
                  </el-form-item>
                </td>
                <td>担任职务</td>
                <td colspan="3">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="DRZW"
                  >
                    <el-input
                      size="small"
                      v-model="forminfo.DRZW"
                      placeholder="若没有担任职务此处填“无”"
                    >
                    </el-input>
                  </el-form-item>
                </td>
              </tr>
              <tr>
                <td style="width: 30px">荣誉称号</td>
                <td colspan="7">
                  <el-form-item
                    label-width="0px"
                    style="margin-bottom: 0px"
                    prop="RYCH"
                  >
                    <el-radio-group v-model="forminfo.RYCH">
                      <el-radio label="劳卫工作积极分子"
                        >劳卫工作积极分子</el-radio
                      >
                      <el-radio label="创新创业积极分子"
                        >创新创业积极分子</el-radio
                      >
                      <el-radio label="体育积极分子">体育积极分子</el-radio>
                      <el-radio label="宣传工作积极分子"
                        >宣传工作积极分子</el-radio
                      >
                      <el-radio label="文艺积极分子">文艺积极分子</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </td>
              </tr>
              <tr v-if="forminfo.STEP !== 0 && forminfo.SPZT != 0">
                <td colspan="8" style="text-align: left">
                  <p style="text-indent: 2em">
                    经核查，在星级宿舍评比中该生所在宿舍本学年的等级为{{
                      forminfo.XJSS
                    }}，本学年综合素质测评等级为{{ forminfo.ZHCP }}，在{{
                      forminfo.PXHD
                    }}活动中表现突出，符合“{{
                      forminfo.RYCH
                    }}”评选条件。经班级民主评议，同意其参评“{{ forminfo.RYCH }}”
                    ，特此推荐。
                  </p>
                </td>
              </tr>
            </table>
          </el-card>
          <!--<el-form-item label="附件材料" label-width="120px" class="mb10">
            <el-upload
              class="upload-demo"
              :headers="headers"
              :action="uploadurl"
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :before-remove="beforeRemove"
              :limit="3"
              :on-exceed="handleExceed"
              :file-list="fileList"
            >
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                存在文件必须填写说明，附件类型包括：jpg，gif，bmp，doc，rar，zip，pdf，xls,swf
              </div>
            </el-upload>
          </el-form-item>-->
        </el-form>
      </el-scrollbar>
      <div
        slot="footer"
        class="dialog-footer"
        v-if="forminfo.STEP == 0 || (forminfo.STEP == 1 && forminfo.SPZT == 0)"
      >
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getMyAllDetailInfo } from '../../api/jcxx'
import {
  stuDXGetPC,
  DXSave,
  getDXStuApplyPageList,
  getDXSQFormData,
  getSQBPdf,
} from '../../api/py'
import { getAuthorizeButtonColumnList } from '../../api/system'
import moment from 'moment'
import { baseUrl } from '../../utils/http'
import store from '../../store/index'
export default {
  data() {
    return {
      downurl: baseUrl,
      headers: null,
      uploadurl: baseUrl + 'File/upload',
      fileList: [],
      topfile: [],
      pagination: {
        rows: 10,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      splist: [],
      pcmodel: {},
      showtop: false,
      windowHeight: document.documentElement.clientHeight, // 实时屏幕高度
      keyword: '',
      keyValue: '',
      tableloading: true,
      buttonauth: {},
      dataPageList: [],
      formdata: {},
      detail: {},
      detailTable: [],
      operation: 1, // 当前操作1=添加，0=编辑
      forminfo: {
        FJBH: '',
        LXDH: '',
        JTDZ: '',
        BJMC: '',
        NJ: '',
        ID: '',
        PCID: '',
        XH: '',
        XM: '',
        XB: '',
        CSNY: '',
        MZ: '',
        ZZMM: '',
        JG: '',
        PXHD: '',
        STEP: 0,
        SPZT: 0,
        DRZW: '',
        RYCH: null,
        XJSS: null,
        ZHCP: null,
        fj: [],
      },
      zzmm: [],
      dialogFormVisible: false, // 是否显示弹出框
      dialogDetailVisible: false, // 是否显示详情弹出框
      // 表单验证规则对象
      forminfoRules: {
        DRZW: [{ required: true, message: '请填写担任职务', trigger: 'blur' }],
        RYCH: [{ required: true, message: '请选择荣誉称号', trigger: 'blur' }],
      },
    }
  },
  created() {
    this.headers = {
      Authorization: 'Bearer ' + store.state.token.access_token,
    }
    stuDXGetPC()
      .then((res) => {
        if (res.code === 200) {
          this.showtop = true
          this.pcmodel = res.data
          this.forminfo.PCID = res.data.id
        } else {
          this.showtop = false
        }
      })
      .catch((err) => {
        console.log(err)
        this.showtop = false
      })
    this.formdata = JSON.parse(JSON.stringify(this.forminfo))
    this.getDataPageList()
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
  },
  methods: {
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 显示添加弹出框
    showAdd() {
      this.operation = 1
      this.fileList = []
      this.splist = []
      if (this.operation === 1) {
        getMyAllDetailInfo().then((res) => {
          if (res.code === 200) {
            this.forminfo.XH = res.data.xh
            this.forminfo.XM = res.data.xm
            this.forminfo.XB = res.data.xbm === '1' ? '男' : '女'
            this.forminfo.CSNY = this.dateFormat3(res.data.csrq)
            this.forminfo.JTDZ = res.data.jtdz
            this.forminfo.LXDH = res.data.lxdh
            // this.forminfo.RXSJ = this.dateFormat4(res.data.rxsj)
            this.forminfo.MZ = res.data.mz
            this.forminfo.ZZMM = res.data.zzmm
            this.forminfo.BJMC = res.data.bjmc
            this.forminfo.JG = res.data.jg
            this.forminfo.FJBH = res.data.fjbh
          }
        })
      }
      this.dialogFormVisible = true
    },
    // 保存信息
    saveForm() {
      if (this.operation === 1) {
        this.keyValue = ''
        this.forminfo.ID = ''
      } else {
        this.forminfo.ID = this.keyValue
      }
      this.$refs.formRef.validate(async (valid) => {
        if (!valid) {
          return false
        } else {
          this.forminfo.PCID = this.pcmodel.id
          DXSave(this.forminfo).then((res) => {
            if (res.code === 200) {
              this.$message.success(res.message)
              this.dialogFormVisible = false
              this.dialogDetailVisible = false
              this.getDataPageList()
              stuDXGetPC()
                .then((res) => {
                  if (res.code === 200) {
                    this.showtop = true
                    this.pcmodel = res.data
                  } else {
                    this.showtop = false
                  }
                })
                .catch((err) => {
                  console.log(err)
                  this.showtop = false
                })
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 当添加/编辑弹出框关闭时调用
    closeDialog() {
      this.active = 0
      this.$nextTick(() => {
        this.forminfo = JSON.parse(JSON.stringify(this.formdata))
      })
      this.$refs.formRef.resetFields()
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    reload() {
      this.$router.go(0)
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getDXStuApplyPageList({
        queryJson: JSON.stringify({ keyword: this.keyword }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
            this.tableloading = false
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 时间格式化
    dateFormat: function (row, column) {
      var date = row[column.property]

      if (!date) {
        return ''
      }

      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat2(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    // 时间格式化
    dateFormat3(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM')
    },
    // 时间格式化
    dateFormat4(value) {
      if (!value) {
        return ''
      }

      return moment(value).format('YYYY-MM-DD')
    },
    handleRemove(file, fileList) {
      var index = -1
      for (var i = 0; i < this.fileList.length; i++) {
        if (this.fileList[i].name === file.name) {
          index = i
          break
        }
      }
      if (index >= 0) {
        this.fileList.splice(index, 1)
      }
    },
    handlePreview(file) {
      var id = ''
      if (file.response) {
        id = file.response.data.id
      } else {
        id = file.id
      }
      window.open(baseUrl + 'File/download?id=' + id)
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleSuccess(response, file, fileList) {
      this.fileList.push(fileList[0])
    },
    handleDetail(row) {
      this.operation = 2
      getDXSQFormData({ id: row.id }).then((res) => {
        if (res.code === 200) {
          this.splist = res.data.spjl
          this.forminfo.XH = res.data.xh
          this.forminfo.XM = res.data.xm
          this.forminfo.XB = res.data.xb === '1' ? '男' : '女'
          this.forminfo.CSNY = this.dateFormat3(res.data.csny)
          this.forminfo.JTDZ = res.data.jtdz
          this.forminfo.LXDH = res.data.lxdh
          // this.forminfo.RXSJ = this.dateFormat4(res.data.rxsj)
          this.forminfo.MZ = res.data.mz
          this.forminfo.ZZMM = res.data.zzmm
          this.forminfo.BJMC = res.data.bjmc
          this.forminfo.JG = res.data.jg
          this.forminfo.FJBH = res.data.fjbh
          this.forminfo.RYCH = res.data.rych
          this.forminfo.DRZW = res.data.drzw
          this.forminfo.XJSS = res.data.xjss
          this.forminfo.ZHCP = res.data.zhcp
          this.forminfo.SPZT = res.data.spzt
          this.forminfo.PXHD = res.data.pxhd
          this.forminfo.STEP = res.data.step
          this.dialogFormVisible = true
        } else {
          this.$message.error(res.message)
        }
      })
    },
    print(id) {
      getSQBPdf({ ID: id }, '单项积极分子申请表.pdf').then((res) => {
        var blob = new Blob([res.data], {
          type: 'application/pdf;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
  },
}
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}

.c_table {
  height: 100%;
  overflow-y: auto;
  width: 99%;
  margin: 0 auto;
  margin-top: 20px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
}
.DivTable {
  width: 99%;
  margin: 0 auto;
  margin-top: 10px;
}
table {
  display: table;
  border-collapse: collapse;
  box-sizing: border-box;
  text-indent: initial;
  white-space: normal;
  line-height: normal;
  font-weight: normal;
  font-size: medium;
  font-style: normal;
  color: -internal-quirk-inherit;
  text-align: start;
  border: 1px solid #bdc8d3;
  font-variant: normal;
}
.DivTable tr {
  min-height: 32px;
  border: 0;
}
.DivTable .EdtTitleBack {
  background-color: #fbfafa;
  text-align: right;
  height: 32px;
  overflow: hidden;
}
.DivTable td {
  min-height: 28px;
  border: 0;
  border: 1px solid #bdc8d3;
}
.DivTable .EdtBack {
  background-color: #fff;
  text-align: left;
  height: 32px;
  overflow: hidden;
}
.form-item-group {
  display: flex;
  color: #409eff;
  align-items: center;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
}
.form-item-group i {
  margin-right: 5px;
}
.staffBmTitle {
  margin-top: 1px;
  font-weight: 700;
  text-align: center;
  display: block;
  color: #000;
  font-size: 24px;
  margin-bottom: 10px;
}
.inputnoborder >>> .el-input__inner {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom-color: #000;
}
.staffBmTable {
  width: 100%;
  font-size: 14px;
  text-align: center;
  border-color: #000;
}
.staffBmTable tr td {
  padding: 10px;
}
.cell-class {
  border-color: #000 !important;
}
</style>
